import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import sal from "sal.js";

import "venobox/dist/venobox.min.css";

import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import hiImgOne from "../../public/images/banner/hi_1.png";
import imgTwo from "../../public/images/gallery/gallery-01.jpg";
import imgOne from "../../public/images/gallery/gallery-03.jpg";
import imgThree from "../../public/images/gallery/gallery-05.jpg";
import videoImg from "../../public/images/others/video-06.jpg";
import CardThree from "../Cards/Card-Three";
import UniversityBanner from "./UniversityBanner";

const UniversityClassic = () => {
  
  
  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
      });
    });

    sal({
      threshold: 0.01,
      once: true,
    });
  }, []);

  return (
    <>
      <div className="rbt-slider-main-wrapper position-relative">
        <UniversityBanner />
      </div>

      <div
        className="rbt-video-area bg-color-white rbt-section-gapTop"
        id="about"
      >
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="video-popup-wrapper">
                <Image
                  className="w-100 rbt-radius"
                  src={videoImg}
                  width={638}
                  height={407}
                  property="true"
                  alt="Video Images"
                />
                {/* <Link
                  className="rbt-btn rounded-player popup-video position-to-top rbtplayer"
                  data-vbtype="video"
                  href="https://www.youtube.com/watch?v=nA1Aqp0sPQo"
                >
                  <span>
                    <i className="feather-play"></i>
                  </span>
                </Link> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner pl--50 pl_lg--0 pl_md--0 pl_sm--0">
                <div className="section-title text-start">
                  <h4 className="title">Welcome to GD College</h4>
                  <p className="description mt--10">
                  At GD College, we believe that everyone has the potential to succeed, regardless of their background or gender. Our mission is to empower our students, by providing them with the skills and knowledge they need to achieve financial independence through beauty and cosmetology. We also welcome students of all genders to join us in pursuing their dreams in the industry. Our diploma programs are designed to prepare students for both the workforce and entrepreneurship.
                  </p>
                  <div className="rbt-feature-wrapper mt--10">
                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon" style={{background: 'black'}}>
                        <i className="feather-heart" style={{color: 'white'}}></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">Flexible Classes</h6>
                      </div>
                    </div>

                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon" style={{background: 'black'}}>
                        <i className="feather-book" style={{color: 'white'}}></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">Learn From Anywhere</h6>
                      </div>
                    </div>

                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon" style={{background: 'black'}}>
                        <i className="feather-monitor" style={{color: 'white'}}></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">
                          Experienced Faculty.
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="rbt-category-area bg-color-white rbt-section-gapTop">
        <ServiceEight isHead={false} />
      </div> */}

      <div className="rbt-banner-area rbt-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Swiper
                className="swiper viral-banner-activation rbt-arrow-between"
                slidesPerView={1}
                modules={[Navigation]}
                navigation={{
                  nextEl: ".rbt-arrow-left",
                  prevEl: ".rbt-arrow-right",
                }}
              >
                <div className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide">
                    <div className="thumbnail">
                      <Link href="/programs">
                        <Image
                          className="rbt-radius w-100"
                          src={hiImgOne}
                          width={1305}
                          height={425}
                          property="true"
                          alt="Banner Images"
                        />
                      </Link>
                    </div>
                  </SwiperSlide>

                  {/* <SwiperSlide className="swiper-slide">
                    <div className="thumbnail">
                      <Link href="#">
                        <Image
                          className="rbt-radius w-100"
                          src={hiImgTwo}
                          width={1305}
                          height={425}
                          property="true"
                          alt="Banner Images"
                        />
                      </Link>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide">
                    <div className="thumbnail">
                      <Link href="#">
                        <Image
                          className="rbt-radius w-100"
                          src={hiImgThree}
                          width={1305}
                          height={425}
                          property="true"
                          alt="Banner Images"
                        />
                      </Link>
                    </div>
                  </SwiperSlide> */}
                </div>
                <div className="rbt-swiper-arrow rbt-arrow-left">
                  <div className="custom-overfolow">
                    <i className="rbt-icon feather-arrow-left"></i>
                    <i className="rbt-icon-top feather-arrow-left"></i>
                  </div>
                </div>
                <div className="rbt-swiper-arrow rbt-arrow-right">
                  <div className="custom-overfolow">
                    <i className="rbt-icon feather-arrow-right"></i>
                    <i className="rbt-icon-top feather-arrow-right"></i>
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="rbt-advance-tab-area rbt-section-gapTop bg-color-white">
        <AdvanceTab
          tag=""
          title="About Us."
          desc=" Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts."
        />
      </div> */}

      <div
        className="rbt-program-area rbt-section-gapTop bg-color-white"
        id="program"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Programs</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link className="rbt-btn-link" href="/programs">
                  View All<i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="row g-5">
            {<div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-category-gallery">
                <div className="thumbnail">
                  <Link href="/program-details/15">
                    <Image src={imgOne} property="true" alt="Gallery Images" />
                    <div className="rbt-bg-overlay"></div>
                  </Link>
                  <div className="program-title">
                    <h3 className="title color-white">
                      <Link href="/program-details/15">Esthetician Diploma</Link>
                      <div>
                        {/* <br/>
                        <div style={{'fontSize':'2rem', 'fontWeight':'normal'}}>
                          <i className="feather-calendar"></i> 5 Months (32 hours per week)
                        </div> */}
                        {/* <div style={{'fontSize':'2rem', 'fontWeight':'500'}}>
                          C$ 7500
                        </div> */}
                      </div>
                    </h3>
                  </div>
                </div>
              </div>
            </div>}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-category-gallery">
                <div className="thumbnail">
                  <Link href="/program-details/16">
                    <Image src={imgTwo} property="true" alt="Gallery Images" />
                    <div className="rbt-bg-overlay"></div>
                  </Link>
                  <div className="program-title">
                    <h3 className="title color-white">
                      <Link href="/program-details/16">Clinical Esthetician</Link>
                      <div>
                        {/* <br/>
                        <div style={{'fontSize':'2rem', 'fontWeight':'normal'}}>
                          <i className="feather-calendar"></i> 5 Months (32 hours per week)
                        </div> */}
                        {/* <div style={{'fontSize':'2rem', 'fontWeight':'500'}}>
                          C$ 7500
                        </div> */}
                      </div>
                    </h3>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-category-gallery">
                <div className="thumbnail">
                  <Link href="/program-details/35">
                    <Image
                      src={imgThree}
                      property="true"
                      alt="Gallery Images"
                    />
                    <div className="rbt-bg-overlay"></div>
                  </Link>
                  <div className="program-title">
                    <h3 className="title color-white">
                      <Link href="/program-details/35">Advanced Esthetics Diploma</Link>
                      <div>
                        {/* <br/>
                        <div style={{'fontSize':'2rem', 'fontWeight':'normal'}}>
                          <i className="feather-calendar"></i> 5 Months (32 hours per week)
                        </div> */}
                        {/* <div style={{'fontSize':'2rem', 'fontWeight':'500'}}>
                          C$ 7500
                        </div> */}
                      </div>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="rbt-course-area rbt-section-gapTop rbt-section-gapBottom bg-color-white"
        id="course"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Top Courses</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link className="rbt-btn-link" href="/courses">
                  View All<i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="row g-5">
            <CardThree/>
          </div>
        </div>
      </div>

      {/* <div
        className="rbt-rbt-blog-area rbt-section-gapTop bg-color-white"
        id="blog"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--30">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Our Research</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link className="rbt-btn-link" href="/blog-list">
                  Browse gd-college Post<i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <BlogGrid isPagination={false} top={false} start={0} end={3} />
        </div>
      </div>

      <div className="rbt-event-area bg-color-white rbt-section-gapTop">
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Our Events</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link className="rbt-btn-link" href="#">
                  University Upcoming Events
                  <i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <Events
            start={0}
            end={4}
            isPagination={false}
            parentClass="card-list-2 event-list-card"
            childClass="col-lg-6 col-md-6 col-12"
            getEvents={EventData}
          />
        </div>
      </div> */}
      {/* <div
        className="rbt-testimonial-area bg-color-white rbt-section-gap overflow-hidden"
        id="testimonial"
      >
        <div className="container">
          <Course title="University Tuition & Fees" />
        </div>
      </div> */}

      {/* <div className="rbt-testimonial-area bg-color-light rbt-section-gap overflow-hidden"> */}
        {/* <div className="wrapper mb--60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle" style={{background: 'black', color: 'white'}}>
                    EDUCATION FOR EVERYONE
                  </span>
                  <h2 className="title">
                    Testimonials
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <Testimonial /> */}
      {/* </div> */}
      {/* <div className="rbt-gallery-area">
        <Gallery />
      </div> */}
      {/* <div className="rbt-brand-area bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row align-items-center g-5">
            <BrandOne />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default UniversityClassic;
