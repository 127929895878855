import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/logo.png";

import CopyRight from "./CopyRight";

import FooterData from "../../data/footer.json";
import SingleFooter from "./FooterProps/SingleFooter";

const FooterOne = ({ bgColor }) => {
  return (
    <>
      <footer
        className={`rbt-footer footer-style-1 border_top ${
          bgColor ? bgColor : "bg-color-white"
        } overflow-hidden`}
      >
        <div className="footer-top">
          <div className="container">
            {FooterData &&
              FooterData.footerTwo.map((footer, index) => (
                <div className="row g-5" key={index}>
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="footer-widget">
                      <div className="logo">
                        <Link href="/">
                          <Image
                            src={logo}
                            width={152}
                            height={50}
                            alt="Edu-cause"
                          />
                        </Link>
                      </div>

                      <p className="description mt--20">&nbsp;</p>

                      <ul className="social-icon social-default justify-content-start">
                        {footer.socialLink.map((value, innerIndex) => (
                          <li key={innerIndex}>
                            <Link href={value.link}>
                              <i className={value.icon}></i>
                            </Link>
                          </li>
                        ))}
                        <li className='tiktok-icon-mob'>
                          <Link href="https://www.tiktok.com/@gdcollege3">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                              <path d="M448 209.9a210.1 210.1 0 0 1 -122.8-39.3V349.4A162.6 162.6 0 1 1 185 188.3V278.2a74.6 74.6 0 1 0 52.2 71.2V0l88 0a121.2 121.2 0 0 0 1.9 22.2h0A122.2 122.2 0 0 0 381 102.4a121.4 121.4 0 0 0 67 20.1z"/>
                            </svg>
                          </Link>
                        </li> 
                      </ul>

                      <div className="contact-btn mt--30">
                        <Link
                          className="rbt-btn hover-icon-reverse radius-round"
                          href="/contact"
                          style={{background: 'black', color: 'white'}}
                        >
                          <div className="icon-reverse-wrapper">
                            <span className="btn-text">Connect With Us</span>
                            <span className="btn-icon">
                              <i className="feather-arrow-right"></i>
                            </span>
                            <span className="btn-icon">
                              <i className="feather-arrow-right"></i>
                            </span>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* <SingleFooter
                    classOne="col-lg-2 col-md-6 col-sm-6 col-12 mt--30"
                    title="Useful Links"
                    footerType={footer.usefulLinks}
                  /> */}
                  <SingleFooter
                    classOne="col-lg-2 col-md-6 col-sm-6 col-12"
                    title="About"
                    footerType={footer.ourCompany}
                  />

                  <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                    <div className="footer-widget">
                      <h5 className="ft-title">Get Contact</h5>
                      <ul className="ft-link">
                        <li>
                          <span>Phone: </span>
                          <Link href={`Tel: +1 ${footer.phone}`}>+1 {footer.phone}</Link>
                        </li>
                        <li>
                          <span>E-mail:</span>{" "}
                          <Link href={`mailto:${footer.mail}`}>
                            {footer.mail}
                          </Link>
                        </li>
                        <li>
                          <span>Address:</span><br/> {footer.address}
                        </li>
                      </ul>

                      {/* <form className="newsletter-form mt--20" action="#">
                        <h6 className="w-600">Newsletter</h6>
                        <p className="description">{footer.descriptionTwo}</p>

                        <div className="form-group right-icon icon-email mb--20">
                          <input
                            id="email"
                            type="email"
                            placeholder="Enter Your Email Here"
                          />
                        </div>

                        <div className="form-group mb--0">
                          <button
                            className="rbt-btn rbt-switch-btn radius-round btn-sm"
                            type="submit"
                            style={{background: 'black', color: 'white'}}
                          >
                            <span data-text="Submit Now">Subscribe Now</span>
                          </button>
                        </div>
                      </form> */}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <CopyRight />
      </footer>
    </>
  );
};

export default FooterOne;
