import Image from "next/image";

import CourseDetails from "../../data/course-details/courseData.json";

const CardThree = () => {
  const firstFourCourses = CourseDetails && CourseDetails.courseDetails.filter((data, index) => index < 4);
  const coursesHomePage = JSON.parse(JSON.stringify(firstFourCourses));

  return (
    <>
      {coursesHomePage &&
        coursesHomePage.map((data, index) => (
          <div
            className="col-lg-6 col-md-6 col-sm-6 col-12 mt--30"
            data-sal-delay="150"
            data-sal="slide-up"
            data-sal-duration="800"
            key={index}
          >
            <div className="rbt-card variation-01 rbt-hover card-list-2">
              <div className="rbt-card-img">
                {/* <Link href="#"> */}
                  <Image
                    src={data.courseImg}
                    width={231}
                    height={324}
                    alt="Card image"
                  />
                {/* </Link> */}
              </div>
              <div className="rbt-card-body">
                <div className="rbt-card-top">
                  <div className="rbt-review">
                    {/* <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div> */}
                  </div>
                </div>
                <h4 className="rbt-card-title">{data.courseTitle}</h4>
                <ul className="rbt-meta">
                  <li>
                    <i className="feather-book"></i>
                    {data.lesson}
                  </li>
                </ul>
                <p className="rbt-card-text">{data.desc}</p>
                <div className="rbt-card-bottom">
                  {/* <div className="rbt-price">
                    <span className="current-price">C${data.price}</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};

export default CardThree;
