import { GoogleTagManager } from '@next/third-parties/google';
import Head from "next/head";

const PageHead = ({ title }) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content="Page Description" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="favicon.png" />
        <link rel="icon" href="/pages/favicon.png" />
        <GoogleTagManager gtmId="AW-715107438" />
        <GoogleTagManager gtmId="GT-MBT2FMB" />
        <GoogleTagManager gtmId="G-BNS91FYXZ4" />
      </Head>
    </>
  );
};

export default PageHead;
